import "bootstrap/dist/css/bootstrap.css";
import getaccess from "../../assets/getaccess.png";
import applestore from "../../assets/applestore.svg";
import playstore from "../../assets/playstore.svg";
import { useNavigate } from "react-router-dom";
import getaccessMobile from "../../assets/getaccessMobile.png";

import "../../styles/index.css";

function GetAccessPage() {
  let navigate = useNavigate();

  return (
    <>
      <div style={{ backgroundColor: "#F9FAFC" }}>
        <section className="getaccess container py-3 py-md-5 ">
          <div
            style={{ marginBottom: "-4%" }}
            className="container overflow-hidden"
          >
            <div className="row gy-5 mt-1">
              <div className="col-12">
                <div className="row align-items-center gy-3 gy-md-0 gx-xl-5">
                  <div className="col-xs-12 col-md-6">
                    <div className="img-wrapper position-relative bsb-hover-push">
                      <a href="#!">
                        <img loading="lazy" src={getaccess} alt="getaccess" />
                      </a>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div>
                      <p
                        style={{ fontWeight: 500 }}
                        className="h1 dispaly-5 mb-3"
                      >
                        <a className="link-dark text-decoration-none" href="#!">
                          Be the first to get access to our app
                        </a>
                      </p>
                      <p
                        style={{ fontWeight: 500, marginTop: "4%" }}
                        className="mb-4"
                      >
                        We are giving away exciting gifts for the first 1000
                        people to get the Livio App. Join the waitlist now to
                        get notified immediately the app launches. We are
                        excited to welcome you into our community of people who
                        want to make smarter food choices.
                      </p>
                      <button
                        onClick={() => {
                          navigate("/waitlist");
                        }}
                        style={{
                          borderColor: "#30B15C",
                          borderWidth: 3,
                          color: "#30B15C",
                          fontWeight: "bold",
                        }}
                        className="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20"
                      >
                        Get Early Access
                      </button>

                      <div style={{ marginTop: 50 }}>
                        <p className="text-secondary text-dark">
                          Available soon on
                        </p>
                        <div>
                          <img
                            style={{ width: "30%", marginRight: "5%" }}
                            src={applestore}
                            alt="apple playstore"
                          />
                          <img
                            style={{ width: "34%" }}
                            src={playstore}
                            alt="google playstore"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <-- Show on Mobile, Hide on Laptop ---> */}
      <section
        style={{ backgroundColor: "#F9FAFC" }}
        className="getaccess-mb container py-3 py-md-5"
      >
        <div className="container overflow-hidden">
          <div className="row gy-5">
            <div className="col-12">
              <div className="row align-items-center gy-3 gy-md-0 gx-xl-5">
                <div className="col-xs-12 col-md-6">
                  <div className="img-wrapper text-center">
                    <img
                      className="text-center"
                      loading="lazy"
                      src={getaccessMobile}
                      alt="getaccessMobile"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div>
                    <p
                      style={{ fontWeight: "normal", fontSize: 28 }}
                      className="mb-3 text-center"
                    >
                      Be the First to Get Our App.
                    </p>
                  </div>

                  <div
                    className="eat-right-mb"
                    style={{ justifyContent: "center" }}
                  >
                    <p
                      style={{ fontWeight: "normal", fontSize: "16" }}
                      className="eat-right-mb mb-4"
                    >
                      We are giving away exciting gifts for the first 1000
                      people to get the Livio App. Join the waitlist now to get
                      notified immediately the app launches. We are excited to
                      welcome you into our community of people who want to make
                      smarter food choices.
                    </p>
                    <button
                      onClick={() => {
                        navigate("/waitlist");
                      }}
                      style={{
                        marginLeft: "20%",
                        borderColor: "#30B15C",
                        borderWidth: 3,
                        color: "#30B15C",
                        fontWeight: "bold",
                      }}
                      className="eat-right-mb btn fs-5 btn-md btn-outline-secondary rounded-pill px-20"
                    >
                      Get Early Access
                    </button>
                  </div>

                  <div className="eat-right-mb" style={{ marginTop: 40 }}>
                    <p
                      style={{ marginLeft: "25%", fontWeight: 600 }}
                      className="text-secondary text-dark"
                    >
                      Available soon on
                    </p>
                    <div>
                      <img
                        style={{ width: "45%", marginRight: "5%" }}
                        src={applestore}
                        alt="apple"
                      />
                      {/* <img className="playstore" src={applestore} alt="apple" /> */}
                      <img
                        style={{ width: "50%" }}
                        src={playstore}
                        alt="playstore"
                      />
                    </div>
                  </div>
                  {/* <--End Of Show on Mobile, Hide on Laptop ---> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <-- Show on Mobile, Hide on Laptop ---> */}
    </>
  );
}

export default GetAccessPage;

// import 'bootstrap/dist/css/bootstrap.css';
// import getaccess from "../assets/getaccess.png";

// function GetAccessPage() {
//   return (
//     <>
//         <section className="py-3 py-md-5">
//             <div className="container overflow-hidden">
//                     <div className="row gy-5">
//                     <div className="col-12">
//                         <div className="row align-items-center gy-3 gy-md-0 gx-xl-5">
//                         <div className="col-xs-12 col-md-6">
//                             <div className="img-wrapper position-relative bsb-hover-push">
//                             <a href="#!">
//                                 <span className="badge rounded-pill text-bg-warning position-absolute top-0 start-0 m-3">Photography</span>
//                                 <img className="img-fluid rounded w-100 h-100 object-fit-cover" loading="lazy" src={getaccess} alt="Photography" />
//                             </a>
//                             </div>
//                         </div>
//                         <div className="col-xs-12 col-md-6">
//                             <div>
//                             <p className="text-secondary mb-1">Sep 17, 2022</p>
//                             <h2 className="h1 mb-3"><a className="link-dark text-decoration-none" href="#!">Get into your best shape you're proud of</a></h2>
//                             <p className="mb-4">
//                                 With our Meal Planner, you gain insights into your eating patterns, and earn points based on your food intake and activity. Every week, get advice on how to improve and change your relationship with food for good.
//                             </p>
//                             <a className="btn btn-primary" href="#!" target="_self">Join Waitlist</a>
//                             </div>
//                         </div>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </section>

//     </>
//   );
// }

// export default GetAccessPage;
