import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import adminSignup from "../../assets/adminSignup.png";
import waitlistbg from "../../assets/waitlistbg.png";
import NavBar from "../home/NavBar";
import { useNavigate } from "react-router-dom";
import success from "../../assets/success.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import google from "../../assets/google.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import "../../styles/index.css";

function AdminSignup() {
  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  // For hide or show password
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handlePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  //End of for hide or show password

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("password", password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://staging.api.circlenow.app/livio/api/v1/waitlist",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setOpen(true);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <NavBar />

      <section style={{ marginTop: 20 }} className="p-3 p-md-4 p-xl-5">
        {/* Beginning of div container */}
        <div className="container">
          <div className="row ">
            <div style={{ paddingRight: 15 }} className="col-12 col-md-5">
              <div className="row">
                <div className="col-12">
                  <div className="mt-5">
                    <h2 style={{ fontWeight: 600 }} className="h3 fw-bold">
                      Welcome Back
                    </h2>
                    <h3
                      style={{ marginTop: 10 }}
                      className="fs-6 fw-normal text-secondary m-0"
                    >
                      Please enter your preferred email and password.
                    </h3>
                  </div>
                </div>
              </div>

              <form style={{ marginTop: 50 }} onSubmit={handleSubmit}>
                <div className="row gy-3 gy-md-4 overflow-hidden">
                  <div className="col-12 mt-3">
                    <label
                      style={{ color: "#474D66", fontWeight: "normal" }}
                      for="email"
                      className="form-label fw-bold"
                    >
                      Email address <span className="text-danger">*</span>
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ height: 50, borderRadius: 10 }}
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter your email address"
                      required
                    />
                  </div>

                  {/*Beginning of Password Input */}
                  <div className="col-12">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        style={{ color: "#474D66", fontWeight: "normal" }}
                        for="password"
                        className="form-label fw-bold"
                      >
                        Password <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="input-group">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ height: 50, borderTopLeftRadius: 10 }}
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder="Password"
                        required
                      />

                      <span className="input-group-text">
                        <FontAwesomeIcon
                          icon={passwordVisible ? faEyeSlash : faEye}
                          onClick={handlePasswordVisibility}
                        />
                      </span>
                    </div>
                  </div>
                  {/*End of Password Input */}

                  {/*Beginning Confirm-Password Input */}
                  <div className="col-12">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        style={{ color: "#474D66", fontWeight: "normal" }}
                        for="password"
                        className="form-label fw-bold"
                      >
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="input-group">
                      <input
                        type={passwordVisible2 ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={{ height: 50, borderTopLeftRadius: 10 }}
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder="Password"
                        required
                      />

                      <span className="input-group-text">
                        <FontAwesomeIcon
                          icon={passwordVisible2 ? faEyeSlash : faEye}
                          onClick={handlePasswordVisibility2}
                        />
                      </span>
                    </div>
                  </div>
                  {/*End of Confirm-Password Input */}

                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        name="iAgree"
                        id="iAgree"
                        required
                      />
                      <label
                        style={{
                          color: "#474D66",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                        className="form-check-label text-secondary"
                        for="iAgree"
                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-grid">
                      <button
                        style={{
                          backgroundColor: "#51D27D",
                          height: 50,
                          borderRadius: 10,
                          color: "white",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                        className="btn bsb-btn-xl fw-bold"
                        type="submit"
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <div style={{ marginTop: "5%" }} className="col-12">
                <div className="d-grid">
                  <button
                    style={{
                      backgroundColor: "#ffffff",
                      height: 50,
                      borderRadius: 10,
                      color: "white",
                    }}
                    className="btn bsb-btn-xl fw-bold btn-outline-dark align-items-center"
                  >
                    <span
                      style={{
                        color: "#474D66",
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                      className="ms-2 fs-6 flex-grow-1"
                    >
                      <img
                        style={{ marginRight: 10, marginTop: -2 }}
                        loading="lazy"
                        src={google}
                        alt="google"
                      />
                      Continue with Google
                    </span>
                  </button>
                </div>
              </div>

              <p
                style={{
                  color: "#696F8C",
                  marginTop: 20,
                  fontSize: 14,
                  fontWeight: 500,
                }}
                className="text-center"
              >
                Don’t have an account?
                <button
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#51D27D",
                    border: "none",
                    background: "none",
                  }}
                >
                  Sign up today!
                </button>
              </p>
            </div>

            {/*Beginning of Hide Image on mobile */}
            <div
              style={{ backgroundImage: `url(${waitlistbg})` }}
              className="img-fluid col-12 col-md-1 text-center waitlistIndex"
            ></div>

            <div
              style={{ backgroundImage: `url(${waitlistbg})` }}
              className="col-12 col-md-6 text-center waitlistIndex"
            >
              <img
                style={{ borderRadius: 50 }}
                src={adminSignup}
                alt="adminLogin"
                loading="lazy"
                className="img-fluid rounded w-100 h-100 object-fit-cover"
              />
            </div>
            {/*End of Hide Image on mobile */}
          </div>
        </div>
        {/*End of div container */}

        {/* Modal is outside the div */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              backgroundColor: "white",
              top: "50%",
              width: 400,
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              borderRadius: "5%",
            }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md rounded "
              role="document"
            >
              <div style={{ borderRadius: 20 }} className="modal-content  ">
                <div className="modal-body text-center p-lg-2 ">
                  <img
                    style={{ marginTop: 50 }}
                    loading="lazy"
                    src={success}
                    alt="Success"
                  />

                  <h4
                    style={{ fontWeight: "initial" }}
                    className="text-dark mt-4 fw-bold fs-4"
                  >
                    You are now part of the
                  </h4>
                  <p
                    style={{ color: "#30B15C", marginTop: -5 }}
                    className=" fs-4 fw-bold"
                  >
                    Livio family!
                  </p>

                  <p
                    style={{ color: "#696F8C" }}
                    className="mt-3 fs-6 text-secondary"
                  >
                    Get early access to everything
                    <br />
                    happening in the livio community!
                  </p>

                  <hr
                    style={{
                      width: "100%",
                      height: 2,
                      marginTop: 60,
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  />
                  <button
                    onClick={() => {
                      navigate("/");
                    }}
                    type="button"
                    style={{
                      border: "none",
                      alignSelf: "center",
                      backgroundColor: "white",
                      borderColor: "white",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      marginTop: 40,
                      marginBottom: 40,
                    }}
                    className=" fs-5 text-dark link-secondary"
                  >
                    Go Back Home
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
}

export default AdminSignup;
