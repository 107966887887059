// //https://stackoverflow.com/questions/68781851/react-chart-js-2-error-in-modules-but-was-already-installed-in-the-package-json
// //https://www.npmjs.com/package/react-chartjs-2

import "bootstrap/dist/css/bootstrap.css";
//https://bootstrapbrain.com/component/bootstrap-line-chart-card-example/#preview
import DashboardRecipeGraph from "./dashboardRecipeGraph";
import DashboardRecipeGraph2 from "./dashboardRecipeGraph2";
import users from "../../assets/users.png";

import React from "react";

const DashboardRecipeGraphIndex = () => {
  return (
    <>
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="row ">
            {/* First Graph */}
            <div className="col-12 col-lg-6 ">
              <div className="card widget-card border-light shadow-sm">
                <div className="card-body p-4">
                  <div className="table-responsive">
                    <DashboardRecipeGraph2 />
                  </div>

                  {/* Active Users Div */}
                  <div style={{ marginTop: "5%" }}>
                    <h5
                      style={{
                        fontSize: 18,
                        fontWeight: 500,
                        color: "#2D3748",
                      }}
                      className="card-title widget-card-title"
                    >
                      Active Users
                    </h5>
                    <h5
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: "#2D3748",
                        marginTop: "-2%",
                      }}
                      className="card-title widget-card-title"
                    >
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "#48BB78",
                        }}
                      >
                        (+23%)
                      </span>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#A0AEC0",
                        }}
                      >
                        {" "}
                        than last week
                      </span>
                    </h5>
                  </div>
                  {/*End Of Active Users Div */}

                  {/* The 4 Users Div */}
                  <div
                    style={{
                      marginTop: "5%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Div 1 */}
                    <div style={{ width: "15%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          className="rounded"
                          loading="lazy"
                          src={users}
                          alt="👱‍♂️"
                        />
                        <h5
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: "#A0AEC0",
                            marginTop: "8%",
                          }}
                          className="card-title"
                        >
                          Users
                        </h5>
                      </div>

                      <h6
                        style={{
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#000",
                          marginTop: "8%",
                        }}
                        className="card-subtitle mb-2 text-muted"
                      >
                        32,984
                      </h6>

                      <div style={{ height: 5 }} className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "32.98%",
                            height: 5,
                            backgroundColor: "#3366FF",
                          }}
                          aria-valuenow="32.98"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    {/*End Of Div 1 */}

                    {/* Div 2 */}
                    <div style={{ width: "15%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          className="rounded"
                          loading="lazy"
                          src={users}
                          alt="👱‍♂️"
                        />
                        <h5
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: "#A0AEC0",
                            marginTop: "8%",
                          }}
                          className="card-title"
                        >
                          Users
                        </h5>
                      </div>

                      <h6
                        style={{
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#000",
                          marginTop: "8%",
                        }}
                        className="card-subtitle mb-2 text-muted"
                      >
                        32,984
                      </h6>

                      <div style={{ height: 5 }} className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "32.98%",
                            height: 5,
                            backgroundColor: "#3366FF",
                          }}
                          aria-valuenow="32.98"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    {/*End Of Div 2 */}

                    {/* Div 3 */}
                    <div style={{ width: "15%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          className="rounded"
                          loading="lazy"
                          src={users}
                          alt="👱‍♂️"
                        />
                        <h5
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: "#A0AEC0",
                            marginTop: "8%",
                          }}
                          className="card-title"
                        >
                          Users
                        </h5>
                      </div>

                      <h6
                        style={{
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#000",
                          marginTop: "8%",
                        }}
                        className="card-subtitle mb-2 text-muted"
                      >
                        32,984
                      </h6>

                      <div style={{ height: 5 }} className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "32.98%",
                            height: 5,
                            backgroundColor: "#3366FF",
                          }}
                          aria-valuenow="32.98"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    {/*End Of Div 3 */}

                    {/* Div 4 */}
                    <div style={{ width: "15%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          className="rounded"
                          loading="lazy"
                          src={users}
                          alt="👱‍♂️"
                        />
                        <h5
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: "#A0AEC0",
                            marginTop: "8%",
                          }}
                          className="card-title"
                        >
                          Users
                        </h5>
                      </div>

                      <h6
                        style={{
                          fontSize: 18,
                          fontWeight: 700,
                          color: "#000",
                          marginTop: "8%",
                        }}
                        className="card-subtitle mb-2 text-muted"
                      >
                        32,984
                      </h6>

                      <div style={{ height: 5 }} className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: "32.98%",
                            height: 5,
                            backgroundColor: "#3366FF",
                          }}
                          aria-valuenow="32.98"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    {/*End Of Div 4 */}
                  </div>
                  {/* The 4 Users Div */}
                </div>
              </div>
            </div>
            {/*End Of First Graph */}

            {/* Second Graph */}
            <div className="col-12 col-lg-6 ">
              <div className="card widget-card border-light shadow-sm">
                <div className="card-body p-4">
                  <div style={{ marginBottom: "8%" }}>
                    <h5
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: "#2D3748",
                      }}
                      className="card-title widget-card-title"
                    >
                      Subscription rate
                    </h5>
                    <h5
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: "#2D3748",
                        marginTop: "-2%",
                      }}
                      className="card-title widget-card-title"
                    >
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          color: "#48BB78",
                        }}
                      >
                        (+5) more
                      </span>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#A0AEC0",
                        }}
                      >
                        {" "}
                        in 2024
                      </span>
                    </h5>
                  </div>

                  <div className="table-responsive">
                    <DashboardRecipeGraph />
                  </div>
                </div>
              </div>
            </div>
            {/*End Of Second Graph */}
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardRecipeGraphIndex;
