import "bootstrap/dist/css/bootstrap.css";
import NavBar from "./NavBar";
import HeroPage from "./HeroPage";
// import HeroPage2 from './components/HeroPage2';
import EatRightPage from "./EatRightPage";
import FeaturesPage from "./FeaturesPage";
import ExclusiveRecipePage from "./ExclusiveRecipePage";
import GetIntoShapePage from "./GetIntoShapePage";
// import SleepPage from './SleepPage';
// import MonitorMealPlanPage from './MonitorMealPlanPage';
import LiveLongPage from "./LiveLongPage";
import GetAccessPage from "./GetAccessPage";
import OurMissionPage from "./OurMissionPage";
import JoinWaitListPage from "./JoinWaitListPage";
import FooterPage from "./Footer";
import Footer from "../../components/footer/Footer";
import ChefTestimonialCard from "../../components/chefTestimonials/ChefTestimonials";
import { useState } from "react";
import GetStartedModal from "../../components/getStartedModal/GetStartedModal";
// import Footer2 from './Footer2';

function HomePageIndex() {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleShowGetStarted = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <>
      {showModal && <GetStartedModal onClose={closeModal} />}
      <NavBar toggleShowGetStarted={toggleShowGetStarted} />
      {/* <HeroPage2 /> */}
      <HeroPage toggleShowGetStarted={toggleShowGetStarted} />
      <EatRightPage />
      <FeaturesPage />
      <ExclusiveRecipePage toggleShowGetStarted={toggleShowGetStarted} />
      <GetIntoShapePage toggleShowGetStarted={toggleShowGetStarted} />
      {/* <SleepPage /> */}
      <LiveLongPage toggleShowGetStarted={toggleShowGetStarted} />
      {/* <MonitorMealPlanPage /> */}
      <GetAccessPage />
      <ChefTestimonialCard />
      <OurMissionPage />
      <JoinWaitListPage toggleShowGetStarted={toggleShowGetStarted} />
      <Footer />
      {/* <FooterPage /> */}
      {/* <Footer2 /> */}
    </>
  );
}

export default HomePageIndex;
