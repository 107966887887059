//https://www.w3schools.com/css/css3_buttons.asp
//Offcanvas Stuff
//https://www.w3schools.com/bootstrap5/tryit.asp?filename=trybs_offcanvas&stacked=h
import "bootstrap/dist/css/bootstrap.css";
// import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import credpalLogo from "../../assets/credpalLogo.png";
import twitterNav from "../../assets/twitterNav.png";
import instaNav from "../../assets/instaNav.png";
import "../../styles/index.css";

function NavBar({ toggleShowGetStarted }) {
  let navigate = useNavigate();

  return (
    <>
      <nav className="mynavbar fixed-top navbar navbar-expand-md bg-light bsb-navbar bsb-navbar-hover bsb-navbar-caret">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
          </a>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>

          <div className=" collapse navbar-collapse ">
            <ul className="navbar-nav justify-content-center flex-grow-1"></ul>

            <button
              style={{ backgroundColor: "#30B15C", borderColor: "#30B15C" }}
              onClick={() => {
                // navigate("/waitlist");
                toggleShowGetStarted && toggleShowGetStarted();
              }}
              className="text-white btn btn-lg  rounded-pill fs-6 fw-bold text-secondary"
            >
              Get Started
            </button>
          </div>
        </div>
      </nav>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <a className="navbar-brand" href="/">
            <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <button
            style={{
              backgroundColor: "#51D27D",
              marginTop: "15%",
              fontSize: 14,
              fontWeight: 600,
              color: "white",
              width: "50%",
              padding: "3%",
              borderRadius: 50,
              border: "none",
            }}
            onClick={() => {
              //   navigate("/waitlist");
              toggleShowGetStarted && toggleShowGetStarted();
            }}
          >
            Get Started
          </button>
          <hr style={{ border: "1px solid black" }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontWeight: "bold", fontSize: 16 }}>Get in touch</p>
            <div>
              <a href="https://x.com/livio_food">
                <img
                  src={twitterNav}
                  alt="twitter"
                  style={{ marginRight: 10 }}
                />
              </a>
              <a href="https://www.instagram.com/liviofood">
                <img src={instaNav} alt="insta" />
              </a>
            </div>
          </div>

          <p
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "#696F8C",
              textAlign: "center",
              marginTop: "15%",
            }}
          >
            © 2024 Livio Inc. All rights reserved.
          </p>
        </div>
      </div>

      {/* <-- Show on Mobile, Hide on Laptop ---> */}
      <nav className="mynavbar-mb fixed-top navbar navbar-expand-md bg-light bsb-navbar bsb-navbar-hover bsb-navbar-caret">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
          </a>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>

          <div className=" collapse navbar-collapse ">
            <ul className="navbar-nav justify-content-center flex-grow-1"></ul>

            <button
              style={{ backgroundColor: "#30B15C", borderColor: "#30B15C" }}
              // onClick={() =>{ navigate("/waitlist")}}
              onClick={() => {
                // setShowModal((prev) => !prev);
                toggleShowGetStarted && toggleShowGetStarted();
              }}
              className="text-white btn btn-lg  rounded-pill fs-6 fw-bold text-secondary"
            >
              Get Started
            </button>
          </div>
        </div>
      </nav>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <a className="navbar-brand" href="/">
            <img src={credpalLogo} alt="credpalLogo" width="135" height="44" />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <button
            style={{
              backgroundColor: "#51D27D",
              marginTop: "15%",
              fontSize: 14,
              fontWeight: 600,
              color: "white",
              width: "50%",
              padding: "3%",
              borderRadius: 50,
              border: "none",
            }}
            onClick={() => {
              //   navigate("/waitlist");
              // setShowModal((prev) => !prev);
              toggleShowGetStarted && toggleShowGetStarted();
            }}
          >
            Get Started
          </button>
          <hr style={{ border: "1px solid black" }} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontWeight: "bold", fontSize: 16 }}>Get in touch</p>
            <div>
              <a href="https://x.com/livio_food">
                <img
                  src={twitterNav}
                  alt="twitter"
                  style={{ marginRight: 10 }}
                />
              </a>
              <a href="https://www.instagram.com/liviofood">
                <img src={instaNav} alt="insta" />
              </a>
            </div>
          </div>

          <p
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "#696F8C",
              textAlign: "center",
              marginTop: "15%",
            }}
          >
            © 2024 Livio Inc. All rights reserved.
          </p>
        </div>
      </div>

      {/* <-- End of Show on Mobile, Hide on Laptop ---> */}
    </>
  );
}

export default NavBar;

{
  /* <ul className="navbar-nav justify-content-center flex-grow-1"> */
}

{
  /*                     
                    <li className="nav-item">
                        <a className="nav-link  fs-5 text-dark" href="#!">All-stars</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link fs-5 text-dark " href="#!">Recipes</a>
                    </li> */
}
{
  /* </ul> */
}

{
  /* <Link to="/waitlistform"> */
}
