//This code was originallly written by Ogunsanya Emmanuel (http://github.com/CaptainEboy)
//Please if you have any problem reading, understanding or implementing the code please
//contact me on my mail (ogunsanyaemmanuel541@gmail.com) and i would reply ASAP
//I love coding so whether or not i work for credpal i would still be available to assist
//anyone working on this code.
//Success to whoever works next on this code

import "bootstrap/dist/css/bootstrap.css";
//https://www.npmjs.com/package/@fontsource/poppins
import "@fontsource/poppins"; // Defaults to weight 400
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePageIndex from "./pages/home";
import WaitlistPageIndex from "./pages/waitlist";
// import ForgotPasswordIndex from './pages/forgotPassword';
// import ForgotPasswordEmailSentPage from './pages/forgotPassword/emailSent';
// import PasswordResetPage from './pages/forgotPassword/passwordReset';
import SetNewPasswordPage from "./pages/forgotPassword/setNewPassword";
import OTPField from "./pages/forgotPassword/otp";
// import DashboardIndexPage from './pages/dashboard';
// import DashboardPage from './pages/dashboard/newpage';
import DashboardPageIndex from "./pages/dashboard";
import DashboardTable from "./pages/dashboard/dashboardTable";
import DashboardTop from "./pages/dashboard/dasboardTop";
import DashboardNavBar from "./pages/dashboard/dashboardNavBar";
import DashboardNavBar2 from "./pages/dashboard/dashboardNavBar2";
import DashboardRecipeTable from "./pages/dashboard/dashboardRecipeTable";
import DashboardRecipeGraphIndex from "./pages/dashboard/dashboardRecipeGraphIndex";
import DashboardRecipeGraph from "./pages/dashboard/dashboardRecipeGraph";
import DashboardRecipeGraph2 from "./pages/dashboard/dashboardRecipeGraph2";

import AdminPageIndex from "./pages/admin";
import AdminSignUp from "./pages/admin/signup";

import MealPlannerIndex from "./pages/dashboard/mealPlanner";

import ShoppingListIndex from "./pages/dashboard/shopingListIndex";
import ShoppingListTableTop from "./pages/dashboard/shopingListTableUp";
import ShoppingListTableDown from "./pages/dashboard/shopingListTableDown";

import RecipeIngredientsTable from "./pages/dashboard/recipeIngredientsTable";

import MealPlannerNavBar from "./pages/dashboard/mealPlannerNavBar";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import StarChef from "./pages/starChef/StarChef";
import DeleteAccount from "./pages/DeleteAccount";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePageIndex />} />
          {/* <Route path="/" element={<ForgotPasswordIndex />} /> */}
          {/* <Route path="/" element={<ForgotPasswordEmailSentPage />} /> */}
          {/* <Route path="/" element={<PasswordResetPage />} /> */}
          <Route path="/newPassword" element={<SetNewPasswordPage />} />
          <Route path="/otp" element={<OTPField />} />
          {/* <Route path="/" element={<DashboardIndexPage />} /> */}
          {/* <Route path="/" element={<DashboardPage />} /> */}
          <Route path="/dashboard" element={<DashboardPageIndex />} />
          <Route
            path="/dashboardRecipeTable"
            element={<DashboardRecipeTable />}
          />
          <Route
            path="/dashboardRecipeGraphIndex"
            element={<DashboardRecipeGraphIndex />}
          />
          <Route
            path="/dashboardRecipeGraph"
            element={<DashboardRecipeGraph />}
          />
          <Route
            path="/dashboardRecipeGraph2"
            element={<DashboardRecipeGraph2 />}
          />
          <Route path="/dashboardTable" element={<DashboardTable />} />
          <Route path="/dashboardTop" element={<DashboardTop />} />
          <Route path="/dashboardNavbar" element={<DashboardNavBar />} />
          <Route path="/dashboardNavbar2" element={<DashboardNavBar2 />} />

          <Route path="/waitlist" element={<WaitlistPageIndex />} />

          <Route path="/admin" element={<AdminPageIndex />} />
          <Route path="/admin/signup" element={<AdminSignUp />} />

          <Route path="/mealPlanner" element={<MealPlannerIndex />} />

          <Route path="/shoppingListIndex" element={<ShoppingListIndex />} />
          <Route
            path="/shoppingListTableTop"
            element={<ShoppingListTableTop />}
          />
          <Route
            path="/shoppingListTableDown"
            element={<ShoppingListTableDown />}
          />

          <Route
            path="/recipeIngredientsTable"
            element={<RecipeIngredientsTable />}
          />

          <Route path="/mealPlanNavBar" element={<MealPlannerNavBar />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/nutritionist/:chefname" element={<StarChef />} />
        </Routes>
      </Router>

      {/* <WaitlistPageIndex /> */}
      {/* <Modal /> */}
    </>
  );
}

export default App;
