import React from 'react';
import { ComposedChart, Bar, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

// const data = [
//   { name: 'Jan', value: 10, value2: 20 },
//   { name: 'Feb', value: 20, value2: 30 },
//   { name: 'Mar', value: 30, value2: 40 },
//   { name: 'Apr', value: 40, value2: 50 },
//   { name: 'May', value: 50, value2: 60 },
// ];

const data = [
    {
      "name": "Jan",
      "amt": 300
    },
    {
      "name": "Feb",
      "amt": 200
    },
    {
      "name": "Mar",
      "amt": 100
    },
    {
      "name": "Apr",
      "amt": 300
    },
    {
      "name": "May",
      "amt": 500
    },
    {
      "name": "Jun",
      "amt": 400
    },
    {
      "name": "July",
      "amt": 500
    },
    {
        "name": "Aug",
        "amt": 300
      },
      {
        "name": "Sep",
        "amt": 100
      },
      {
        "name": "Oct",
        "amt": 500
      },
      {
        "name": "Nov",
        "amt": 300
      },
      {
        "name": "Dec",
        "amt": 100
      },
  ]
  

const DashboardRecipeGraph2 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 ">
        <ComposedChart style={{ backgroundColor:"#151928"}} width={424.7} height={222} data={data}>
          <XAxis style={{fontSize:1, fontWeight:700, color:"#151928"}} dataKey="name" />
          <YAxis style={{fontSize:10, fontWeight:700, color:"#fff"}}  />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5"  vertical={false} horizontal={false} />
          <Area type="monotone" dataKey="amt" fill="#151928" stroke="#151928" />
          <Bar dataKey="amt" barSize={5} fill="#fff" />
          {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" /> */}
        </ComposedChart>
        </div>
      </div>
    </div>
  );
};

export default DashboardRecipeGraph2;