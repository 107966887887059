import { Link } from "react-router-dom";
import styles from "./ChefTestimonials.module.css";
import Temitope from "../../assets/starChef/tope-main.png";
import TemitopeColored from "../../assets/starChef/tope-main.png";

const ChefTestimonialCard = () => {
  return (
    <div className={styles.testimonialContainer}>
      <div className={styles.testimonialCard}>
        <div className={styles.imageSection}>
          <img
            src={Temitope}
            alt="Testimonial"
            className={styles.profileImage}
          />
          <div className={styles.dots}>
            <span className={`${styles.dot} ${styles.active}`}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
            <span className={styles.dot}></span>
          </div>
        </div>
        <div className={styles.textSection}>
          <h2>Stay Healthy with Temitope: Livio’s Nutrition Expert</h2>
          <p>
            Description: Temitope is a certified health pro and dietitian with
            the Dietitians Association of Nigeria, here to make eating right fun
            and easy. With Livio, you get simple, no-fuss nutrition advice that
            fits your lifestyle and helps you feel your best. Ready to start
            your health journey? Download Livio today and get personalized tips
            to transform your health!
          </p>
          <div className={styles.authorInfo}>
            <img
              src={TemitopeColored}
              alt="Author"
              className={styles.authorImage}
            />
            <div className={styles.authorText}>
              <strong>Temitope Alao-Sanni</strong>
              <p>Food Nutritionist</p>
            </div>
            <Link to="/nutritionist/temitope" className={styles.learnMore}>
              Learn More <span>&rarr;</span>
            </Link>
          </div>
          <Link
            to="/nutritionist/temitope"
            className={styles.accessButton}
            // target="_blank"
          >
            Access Meal Plans
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChefTestimonialCard;
