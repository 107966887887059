import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const OTPField = () => {
  const [otp, setOtp] = useState(['', '', '', '']);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const otpArray = [...otp];
    otpArray[name] = value;
    setOtp(otpArray);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">OTP</span>
            </div>
            {[...Array(4)].map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={otp[index]}
                name={index}
                onChange={handleInputChange}
                className="form-control"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPField;