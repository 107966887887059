import React from "react";
import NavBar from "./home/NavBar";
// import FooterPage from "./home/Footer";

import Footer from "../components/footer/Footer";
import DeleteAccountComponent from "../components/DeleteAccountComponent";

const DeleteAccount = () => {
  return (
    <div>
      <NavBar />

      <DeleteAccountComponent />

      <Footer />
    </div>
  );
};

export default DeleteAccount;
