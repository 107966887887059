
// import Thomas from "../assets/img/team/thomas.jpg"
// import Arthur from "../assets/img/team/arthur.jpg"
// import Polly from "../assets/img/team/polly.jpeg"
// import John from "../assets/img/team/john.jpeg"
// import Grace from "../assets/img/team/grace.jpg"

export const customers = [
    {
        "key": "1",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Male",
        "engagement": "High",
        "status": "High",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "2",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Female",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "3",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Male",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "4",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Female",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "5",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Male",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "6",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Female",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "7",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Male",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    {
        "key": "8",
        "id": "John White",
        "email": "ndeee@gmail.com",
        "mealPreference": "Nigerian Cuisine",
        "gender": "Female",
        "engagement": "Low",
        "status": "Low",
        "dateJoined": "11/12/2024",
    },
    
];
    

export const topRecipes = [
    {
        "key": "1",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "recipes": "Rice and Beans",
        "mealPlanned": "100",
        "reviews": "Satisfactory",
        "saveRate": "60%"
    },
    {
        "key": "2",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "recipes": "Bread and Akara",
        "mealPlanned": "64",
        "reviews": "Poor",
        "saveRate": "10%"
    },
    {
        "key": "3",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "recipes": "Yam and Egg",
        "mealPlanned": "50",
        "reviews": "Satisfactory",
        "saveRate": "100%"
    },
    {
        "key": "4",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "recipes": "Pounded Yam and Egusi Soup",
        "mealPlanned": "40",
        "reviews": "Satisfactory",
        "saveRate": "100%"
    },
    {
        "key": "5",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "recipes": "Bread and Akara",
        "mealPlanned": "20",
        "reviews": "Satisfactory",
        "saveRate": "25%"
    },
    {
        "key": "6",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "recipes": "Yam Porridge",
        "mealPlanned": "52",
        "reviews": "Satisfactory",
        "saveRate": "40%"
    },
    
];
    


export const mealPlanner = [
    {
        "key": "1",
        "food": "Yam and Egg",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "description": "A mild description of the recipe to be upload",
        "selected": "true",
        "selectedTxt": "selected",
    },
    {
        "key": "2",
        "food": "Moin Moin",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "description": "A mild description of the recipe to be upload",
        "selected": "true",
        "selectedTxt": "selected",
    },
    {
        "key": "3",
        "food": "Chin Chin",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "description": "A mild description of the recipe to be upload",
        "selected": "true",
        "selectedTxt": "selected",
    },
    {
        "key": "4",
        "food": "Eba (Cassava Mix)",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "description": "A mild description of the recipe to be upload",
        "selected": "false",
        "selectedTxt": "Unselected",
    },
    {
        "key": "5",
        "food": "Egg Sauce",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "description": "A mild description of the recipe to be upload",
        "selected": "false",
        "selectedTxt": "Unselected",
    },
    {
        "key": "6",
        "food": "Puff Puff",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        "description": "A mild description of the recipe to be upload",
        "selected": "false",
        "selectedTxt": "Unselected",
    },
    
    
];


export const shoppingListTop = [
    {
        "key": "1",
        "food": "A Tuber of Yam ",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
    },
    {
        "key": "2",
        "food": "1 kg of assorted meat",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
       
    },
    {
        "key": "3",
        "food": "500 g of mackerel",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
        
    },
    {
        "key": "4",
        "food": "1 crate of eggs",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
    },
    {
        "key": "5",
        "food": "1 sachet of curry",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
    },
    {
        "key": "6",
        "food": "A cup of sliced onions",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
    },
    {
        "key": "7",
        "food": "2 cups of sliced fluted pumpkins",
        "image": "https://pngtree.com/freepng/male-avatar-simple-cartoon-design_5230556.html",
    },
    
    
];



    