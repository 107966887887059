//This code was originallly written by Ogunsanya Emmanuel (http://github.com/CaptainEboy)
//Please if you have any problem reading, understanding or implementing the code please
//contact me on my mail (ogunsanyaemmanuel541@gmail.com) and i would reply ASAP
//I love coding so whether or not i work for credpal i would still be available to assist
//anyone working on this code.
//Success to whoever works next on this code

import 'bootstrap/dist/css/bootstrap.css';
import ShoppingListTableTop from './shopingListTableUp';
import ShoppingListTableDown from './shopingListTableDown';

function ShoppingListIndex() {
  return (
    <>
        <ShoppingListTableTop />
        <ShoppingListTableDown />
    </>
  );
}

export default ShoppingListIndex;


