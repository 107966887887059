import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import Avatar from "../../assets/Avatar.png";
import arrowUp from "../../assets/arrowUp.png";
import customersImg from "../../assets/customersImg.png";
import membersImg from "../../assets/membersImg.png";
import activeNowImg from "../../assets/activeNowImg.png";

//https://www.npmjs.com/package/react-dropdown-select
import Select from "react-dropdown-select";

function DashboardTop() {
  const profileName2 = "Victor";
  const totalCustomers = "5,423";
  const percentage = "16%";
  const members = "1,893";
  const percentage2 = "1%";
  const activeNow = "189";

  const [profileName, setProfileName] = React.useState("");

  const options = [
    {
      id: 1,
      name: "Victor Adeniyi",
    },
    {
      id: 2,
      name: "Ervin Howell",
    },
  ];

  return (
    <>
      <div className="container text-center">
        {/* Good Morning */}
        <div
          style={{
            marginTop: "2%",
            padding: 5,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{ fontWeight: 600, alignItems: "center", fontSize: 24 }}
            className="h3 "
          >
            Goodmorning {profileName2} 👋🏼,
          </p>

          <p
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={Avatar} alt="Avatar" />
            <Select
              style={{ width: 155, height: 21 }}
              options={options}
              labelField="name"
              valueField="id"
              onChange={(values) => {
                setProfileName(values.id);
              }}
              placeholder="Victor Adeniyi"
            />
          </p>
        </div>
        {/*End Of Good Morning */}

        {/* Customers, Members and Active Now */}
        <div
          style={{
            marginTop: "1%",
            padding: 5,
            borderRadius: 10,
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
          }}
        >
          {/* Total Customers */}
          <div
            style={{
              marginLeft: "2%",
              marginTop: 15,
              flexDirection: "row",
              display: "flex",
              backgroundColor: "white",
              width: "22%",
              justifyContent: "center",
            }}
          >
            <div>
              <img src={customersImg} alt="Avatar" />
            </div>

            <div
              style={{
                marginLeft: "7%",
                marginRight: "2%",
                marginTop: "4%",
                lineHeight: 0.4,
                alignContent: "center",
                marginTop: "4%",
              }}
            >
              <p style={{ fontWeight: 600, fontSize: 14, color: "#ACACAC" }}>
                Total Customers
              </p>
              <p style={{ fontSize: 32, color: "#000000" }}>{totalCustomers}</p>
              <p>
                <img src={arrowUp} alt="Avatar" />
                <span
                  style={{ color: "#30B15C", fontSize: 12, fontWeight: "bold" }}
                >
                  {percentage}{" "}
                </span>
                <span style={{ fontSize: 12 }}>this month</span>
              </p>
            </div>
          </div>
          {/*End Of Total Customers */}

          {/* Divider Line */}
          <div
            style={{
              borderLeft: "2px solid #F0F0F0",
              marginRight: 0,
              marginLeft: 0,
              flexDirection: "row",
              display: "flex",
              backgroundColor: "white",
              width: "1%",
              justifyContent: "center",
            }}
          ></div>
          {/* End Of Divider Line */}

          {/* Total Members */}
          <div
            style={{
              marginTop: 15,
              flexDirection: "row",
              display: "flex",
              backgroundColor: "white",
              width: "20%",
              justifyContent: "center",
            }}
          >
            <div>
              <img src={membersImg} alt="membersImg" />
            </div>

            <div
              style={{
                marginLeft: "7%",
                marginRight: "2%",
                marginTop: "4%",
                lineHeight: 0.4,
                alignContent: "center",
              }}
            >
              <p style={{ fontWeight: 600, fontSize: 14, color: "#ACACAC" }}>
                Members
              </p>
              <p style={{ fontSize: 32, color: "#000000" }}>{members}</p>
              <p>
                <img src={arrowUp} alt="Avatar" />
                <span
                  style={{ color: "#30B15C", fontSize: 12, fontWeight: "bold" }}
                >
                  {percentage2}{" "}
                </span>
                <span style={{ fontSize: 12 }}>this month</span>
              </p>
            </div>
          </div>
          {/*End Of Total Members */}

          {/* Divider Line */}
          <div
            style={{
              borderLeft: "2px solid #F0F0F0",
              marginRight: 0,
              marginLeft: 0,
              flexDirection: "row",
              display: "flex",
              backgroundColor: "white",
              width: "1%",
              justifyContent: "center",
            }}
          ></div>
          {/* End Of Divider Line */}

          {/* Active Now */}
          <div
            style={{
              marginTop: 15,
              flexDirection: "row",
              display: "flex",
              backgroundColor: "white",
              width: "20%",
              justifyContent: "center",
            }}
          >
            <div>
              <img src={activeNowImg} alt="activeNowImg" />
            </div>

            <div
              style={{
                marginLeft: "7%",
                marginRight: "2%",
                marginTop: "4%",
                lineHeight: 0.4,
                alignContent: "center",
              }}
            >
              <p style={{ fontWeight: 600, fontSize: 14, color: "#ACACAC" }}>
                Active Now
              </p>
              <p style={{ fontSize: 32, color: "#000000" }}>{activeNow}</p>
            </div>
          </div>
          {/*End Of Active Now */}
        </div>
        {/*End Of Customers, Members and Active Now */}
      </div>
    </>
  );
}

export default DashboardTop;
