import React from "react";
import styles from "./MealItem.module.css";

const MealItem = ({ title, img, rating }) => {
  return (
    <div className={styles.mealItem}>
      <img src={img} alt={title} className={styles.mealImg} />
      <h3>{title}</h3>
      <p>{"★".repeat(Math.floor(rating))}</p>
    </div>
  );
};

export default MealItem;
