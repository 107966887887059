// //https://stackoverflow.com/questions/68781851/react-chart-js-2-error-in-modules-but-was-already-installed-in-the-package-json
// //https://www.npmjs.com/package/react-chartjs-2

import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const data = [
  { name: 'Jan', value: 10, value2: 30 },
  { name: 'Feb', value: 20, value2: 100  },
  { name: 'Mar', value: 30, value2: 10  },
  { name: 'Apr', value: 40, value2: 20  },
  { name: 'May', value: 50, value2: 50  },
  { name: 'Jun', value: 10, value2: 30  },
  { name: 'July', value: 20, value2: 80  },
  { name: 'Aug', value: 30, value2: 70  },
  { name: 'Sep', value: 40, value2: 5  },
  { name: 'Oct', value: 50, value2: 90  },
  { name: 'Nov', value: 50, value2: 40  },
  { name: 'Dec', value: 50, value2: 60  },
];

const DashboardRecipeGraph = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
            <AreaChart width={400.69} height={296.5} data={data}>
                <Area type="monotone" dataKey="value" stroke="#4FD1C5" fill="#D9D9D9" />
                <Area type="monotone" dataKey="value2" stroke="#000000" fill="#4FD1C5" />
                <XAxis style={{fontSize:8, fontWeight:700, color:"#A0AEC0"}} dataKey="name" />
                <YAxis style={{fontSize:10, fontWeight:700, color:"#A0AEC0", backgroundColor:"#A0AEC0"}} />
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" vertical={false} />
                <Tooltip />
            </AreaChart>
        </div>
      </div>
    </div>
  );
};

export default DashboardRecipeGraph;

