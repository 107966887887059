import "bootstrap/dist/css/bootstrap.css";
// import credpalwaitlist from "../../assets/credpalwaitlist.png";
// import utube from "../../assets/utube.png";
// import wsapp from "../../assets/wsapp.png";
// import linkedin from "../../assets/linkedin.png";
import x from "../../assets/x.png";
import instagram from "../../assets/instagram.png";
import xLaptop from "../../assets/xLaptop.png";
import instaLaptop from "../../assets/instaLaptop.png";
import joinwaitlistBg from "../../assets/joinwaitlistBg.png";

import { useNavigate } from "react-router-dom";

import "../../styles/index.css";

function JoinWaitListPage({ toggleShowGetStarted }) {
  let navigate = useNavigate();

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "-5%" }}
        className=" overflow-hidden text-center py-3 py-md-5"
      >
        <section
          className="joinwaitlist bsb-hero-1 px-3 bsb-overlay bsb-hover-pull img-fluid "
          style={{
            backgroundImage: `url(${joinwaitlistBg})`,
            marginTop: 1,
            width: "100%",
            height: 620,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {/* <section className="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull img-fluid " style={{marginTop:1, backgroundImage: `url(${credpalwaitlist})`, height:'auto', width:'100%', backgroundRepeat:'no-repeat', backgroundSize:'cover' }}> */}
          {/* <section className="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" style={{marginTop:1, height:629, backgroundImage: `url(${credpalwaitlist})`, backgroundRepeat:'no-repeat', backgroundAttachment:"fixed", backgroundSize:'100% 100%' }}> */}
          <div className="col-lg-12 text-center mx-auto">
            <h1
              style={{
                fontWeight: "normal",
                marginTop: "11%",
                fontSize: 59.63,
              }}
              className="  mb-4"
            >
              Join the Livio Waitlist Today!
            </h1>
            <div className="mx-auto">
              <p style={{ fontSize: 18, fontWeight: 400 }} className=" mb-4 ">
                Discover the best wellness mobile app that caters for your daily
                nutritional needs.
                <br />
                Sign up to our waitlist to get notified when we launch!
              </p>
              <div
                style={{ marginTop: "3%" }}
                className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5"
              >
                <button
                  style={{ width: "23%", height: "10%" }}
                  // onClick={() => {
                  //   navigate("/waitlist");
                  // }}
                  onClick={() => {
                    toggleShowGetStarted && toggleShowGetStarted();
                  }}
                  type="button"
                  className="btn btn-dark btn-lg px-4 me-sm-3"
                >
                  Get Started
                </button>
              </div>
            </div>

            <div
              style={{ alignItems: "center", marginTop: "3%" }}
              className="d-grid gap-2 d-sm-flex  mb-5"
            >
              <ul className="m-0 list-unstyled d-flex  gap-3 text-center mx-auto">
                <li>
                  <a href="https://x.com/livio_food">
                    <img loading="lazy" src={xLaptop} alt="xLaptop" />
                  </a>
                </li>

                <li>
                  <a href="https://www.instagram.com/liviofood">
                    <img loading="lazy" src={instaLaptop} alt="instaLaptop" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      {/* <-- Show on Mobile, Hide on Laptop ---> */}
      <div
        style={{ backgroundColor: "#F9FAFC" }}
        className="joinwaitlist-mb overflow-hidden text-center "
      >
        <section>
          <div>
            <h1
              style={{ fontWeight: 500, marginTop: "7%" }}
              className=" mx-0 display-5 mb-4"
            >
              Join the Livio Waitlist Today!
            </h1>

            <p className="m-4 text-center mb-2 ">
              Discover the best wellness mobile app that caters for your daily
              nutritional needs. Sign up to our waitlist to get notified when we
              launch!
            </p>
            <div>
              <button
                style={{ width: "50%", padding: 8, marginTop: "5%" }}
                // onClick={() => {
                //   navigate("/waitlist");
                // }}
                onClick={() => {
                  toggleShowGetStarted && toggleShowGetStarted();
                }}
                type="button"
                className="btn btn-dark rounded-pill px-4 me-sm-3"
              >
                Get Started
              </button>
            </div>

            <div
              style={{ alignItems: "center", marginTop: "6%" }}
              className="d-grid gap-2 d-sm-flex  mb-5"
            >
              <ul className="m-0 list-unstyled d-flex  gap-3 text-center mx-auto">
                <li>
                  <a href="https://x.com/livio_food">
                    <img loading="lazy" src={x} alt="x" />
                  </a>
                </li>

                <li>
                  <a href="https://www.instagram.com/liviofood">
                    <img loading="lazy" src={instagram} alt="instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      {/* <-- End Of Show on Mobile, Hide on Laptop ---> */}
    </>
  );
}

export default JoinWaitListPage;
