import styles from "./StarChefHead.module.css";
import banner from "../../assets/starChef/Frame 1618873604.svg";

const StarChefHead = () => {
  return (
    <div className={styles.headerContainer}>
      <img src={banner} alt="Banner" className={styles.bannerImage} />
    </div>
  );
};

export default StarChefHead;
