import "bootstrap/dist/css/bootstrap.css";
import getintoshape from "../../assets/getintoshape.png";
// import sleep from "../../assets/sleep.png"
import { useNavigate } from "react-router-dom";

import "../../styles/index.css";

function GetIntoShapePage({ toggleShowGetStarted }) {
  let navigate = useNavigate();

  return (
    <>
      <section
        style={{ marginTop: "2%" }}
        className="container py-3 py-md-5 getIntoShape"
      >
        <div className="container overflow-hidden">
          <div className="row gy-5">
            <div className="col-12">
              <div className="row align-items-center gy-3 gy-md-0 gx-xl-5">
                <div className="col-xs-12 col-md-6">
                  <div className="img-wrapper position-relative bsb-hover-push">
                    <a href="#!">
                      <img
                        className="img-fluid rounded w-100 h-100 object-fit-cover"
                        loading="lazy"
                        src={getintoshape}
                        alt="Sports"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div>
                    <h2
                      style={{ fontWeight: 500, fontSize: 48 }}
                      className=" mb-3"
                    >
                      <a className="link-dark text-decoration-none" href="#!">
                        Get into the best
                        <br />
                        shape you’re proud of.
                      </a>
                    </h2>

                    <p style={{ fontWeight: 500 }} className="fs-6 mb-4">
                      The food you eat impacts your calorie intake and
                      metabolism, influencing weight gain and body energy
                      levels. Consult nutrition experts for advice to achieve
                      and maintain a body weight you’re proud of, and stay
                      strong all day long.
                    </p>
                    <button
                      // onClick={() => {
                      //   navigate("/waitlist");
                      // }}
                      onClick={() => {
                        toggleShowGetStarted && toggleShowGetStarted();
                      }}
                      style={{
                        borderColor: "#30B15C",
                        borderWidth: 3,
                        color: "#30B15C",
                        fontWeight: "bold",
                      }}
                      className="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20"
                      href="#!"
                      target="_self"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <-- Show on Mobile, Hide on Laptop ---> */}
      <section className="container py-3 py-md-5 getIntoShape-mb">
        <div className="container overflow-hidden">
          <div className="row gy-5">
            <div className="col-12">
              <div className="row align-items-center gy-3 gy-md-0 gx-xl-5">
                <div className="col-xs-12 col-md-6">
                  <div className="img-wrapper position-relative bsb-hover-push">
                    <a href="#!">
                      <img
                        className="img-fluid rounded w-100 h-100 object-fit-cover"
                        loading="lazy"
                        src={getintoshape}
                        alt="Sports"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div>
                    <h2
                      style={{ fontWeight: 500, fontSize: 28 }}
                      className=" mb-3"
                    >
                      <a className="link-dark text-decoration-none" href="#!">
                        Get into the best
                        <br />
                        shape you’re proud of.
                      </a>
                    </h2>

                    <p style={{ fontWeight: 500 }} className="fs-6 mb-4">
                      The food you eat impacts your calorie intake and
                      metabolism, influencing weight gain and body energy
                      levels. Consult nutrition experts for advice to achieve
                      and maintain a body weight you’re proud of, and stay
                      strong all day long.
                    </p>
                    <button
                      // onClick={() => {
                      //   navigate("/waitlist");
                      // }}
                      onClick={() => {
                        toggleShowGetStarted && toggleShowGetStarted();
                      }}
                      style={{
                        borderColor: "#30B15C",
                        borderWidth: 3,
                        color: "#30B15C",
                        fontWeight: "bold",
                      }}
                      className="btn fs-5 btn-lg btn-outline-secondary rounded-pill px-20"
                      href="#!"
                      target="_self"
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default GetIntoShapePage;
