import React from "react";
import MealItem from "../mealItem/MealItem";
import styles from "./MealPlanner.module.css";
import Stew from "../../assets/starChef/diet-stew.svg";
import Shawarma from "../../assets/starChef/shawarma.png";
import Oatmeal from "../../assets/starChef/oatmeal.png";
import ChickenPepp from "../../assets/starChef/chicken&pepp.png";
import Jollof from "../../assets/starChef/jollof.png";
import Green from "../../assets/starChef/green.png";
import Turkey from "../../assets/starChef/turkey.png";
import Sandwich from "../../assets/starChef/sandwich.png";
import Sardine from "../../assets/starChef/sardine.png";

const meals = [
  { id: 1, title: "Chicken Shawarma", img: Shawarma, rating: 4.5 },
  { id: 2, title: "Oatmeal and Avocado", img: Oatmeal, rating: 3.5 },
  { id: 3, title: "Chicken Peppersoup", img: ChickenPepp, rating: 3.5 },
  { id: 4, title: "Jollofrice and Coleslaw", img: Jollof, rating: 4.5 },
  { id: 5, title: "Green Smoothie", img: Green, rating: 4.5 },
  {
    id: 6,
    title: "Turkey with Steamed Veggies",
    img: Turkey,
    rating: 4.0,
  },
  { id: 7, title: "Chicken Sandwich", img: Sandwich, rating: 3.5 },
  { id: 8, title: "Sardine and Egg Omelette", img: Sardine, rating: 4.5 },
];

const diets = [
  { title: "Weight Loss Diet", text: "Daily meal plan" },
  { title: "Keto Diet", text: "Daily plan" },
  { title: "Diabetes 2 Diet", text: "Daily plan" },
  { title: "Dash Diet", text: "Daily plan" },
];

const MealPlanner = () => {
  return (
    <div className={styles.mealPlanner}>
      <h2>Personal Meal Planners</h2>
      <div className={styles.dietContainer}>
        {diets.map((diet) => (
          <div key={diet.title}>
            <img src={Stew} alt="diet-stew" />
            <div>
              <p>{diet.title}</p>
              <p>{diet.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.mealsContainer}>
        {meals.map((meal) => (
          <MealItem
            key={meal.id}
            title={meal.title}
            img={meal.img}
            rating={meal.rating}
          />
        ))}
      </div>
    </div>
  );
};

export default MealPlanner;
