import "./privacyPolicy.css";
const PrivacyPolicyComponent = () => (
  <div className="policy-container">
    <h1>Privacy Policy</h1>

    <p>
      <strong>Effective Date:</strong> August 12, 2024
    </p>
    <p>
      <strong>Last Updated:</strong> August 12, 2024
    </p>

    <p>
      Thank you for choosing Livio Food, a mobile application provided by
      Peakpoint Innovations Limited Rc No:7544557, a software company registered
      under the laws of the Federal Republic of Nigeria ("we," "us," "our"). We
      are committed to protecting your privacy and ensuring that your personal
      information is handled in a safe and responsible manner. This Privacy
      Policy outlines how we collect, use, and protect your information when you
      use our services.
    </p>

    <h2>1. Governing Law</h2>
    <p>
      This Privacy Policy is chiefly governed by the Nigerian Data Protection
      Act 2023.
    </p>

    <h2>2. Information We Collect</h2>
    <h3>2.1 Personal Information:</h3>
    <p>
      We may collect personally identifiable information ("Personal
      Information") such as:
    </p>
    <ul>
      <li>Name</li>
      <li>Email address</li>
      <li>Contact details</li>
      <li>
        Any other information you voluntarily provide during account
        registration or through interactions with our services.
      </li>
    </ul>

    <h3>2.2 Non-Personal Information:</h3>
    <p>
      We may collect non-personally identifiable information ("Non-Personal
      Information") such as:
    </p>
    <ul>
      <li>
        Device information (e.g., IP address, operating system, browser type)
      </li>
      <li>
        Usage data (e.g., pages viewed, time spent on the app, clickstream data)
      </li>
      <li>Location data (if enabled by your device)</li>
    </ul>

    <h2>3. How We Collect Your Information</h2>
    <p>
      Livio collects personal information through various methods to provide a
      seamless and personalized experience for users:
    </p>
    <ul>
      <li>
        <strong>Registration Forms:</strong> Users provide personal information
        such as name, email address, and password during account registration.
        This information is essential for account creation, authentication, and
        communication purposes.
      </li>
      <li>
        <strong>In-App Forms:</strong> Additional personal details, like
        preferences and dietary restrictions, may be collected through in-app
        forms. These forms help tailor the user experience by offering
        personalized content and recommendations.
      </li>
      <li>
        <strong>Automatic Data Collection:</strong> Livio automatically collects
        certain information when users interact with the app, including device
        information, IP addresses, and usage data (e.g., app features accessed,
        time spent on the app). This information is gathered to enhance app
        performance, improve user experience, and for analytical purposes.
      </li>
      <li>
        <strong>Third-Party Integrations:</strong> Livio may collect personal
        information through integrations with third-party services (e.g., social
        media logins, payment gateways). Information such as social media
        profiles or payment details is obtained securely to facilitate easy
        login and transactions.
      </li>
      <li>
        <strong>Cookies and Tracking Technologies:</strong> Livio uses cookies
        and similar tracking technologies to collect information about user
        interactions with the app. This data helps in understanding user
        behavior, enhancing user experience, and serving relevant content and
        advertisements.
      </li>
    </ul>

    <h2>4. How We Use Your Information</h2>
    <p>We may use the information we collect for the following purposes:</p>
    <ul>
      <li>
        To Provide and Improve Services: To operate, maintain, and enhance our
        app’s features and services.
      </li>
      <li>
        Personalization: To tailor content and recommendations to your
        preferences.
      </li>
      <li>
        Communication: To send you service-related notifications, updates,
        promotional offers, and other information.
      </li>
      <li>Analytics: To analyze usage patterns and improve app performance.</li>
      <li>
        Legal Compliance: To comply with legal obligations, resolve disputes,
        and enforce our agreements.
      </li>
    </ul>

    <h2>5. How We Share Your Information</h2>
    <p>
      We do not sell or rent your Personal Information to third parties.
      However, we may share your information in the following circumstances:
    </p>
    <ul>
      <li>
        <strong>Service Providers:</strong> We may share your information with
        third-party service providers who assist in app operations, including
        hosting, data analysis, payment processing, and customer service. These
        providers are bound by confidentiality obligations.
      </li>
      <li>
        <strong>Legal Requirements:</strong> We may disclose your information if
        required to do so by law or in response to valid legal requests by
        public authorities.
      </li>
      <li>
        <strong>Business Transfers:</strong> In the event of a merger,
        acquisition, or sale of all or a portion of our assets, your information
        may be transferred to the new owner.
      </li>
    </ul>

    <h2>6. Data Retention</h2>
    <p>
      Personal Information collected and processed by us shall be retained only
      for as long as is necessary to fulfill the purposes for which it was
      collected, including for the purpose of satisfying any legal, regulatory,
      accounting, or reporting requirements.
    </p>
    <p>
      In certain cases, the Company may be required to retain personal data for
      a longer period to comply with legal or regulatory obligations, such as
      those related to financial record-keeping, taxation, anti-money
      laundering, or other statutory requirements.
    </p>

    <h2>7. Data Security</h2>
    <p>
      We take the security of your information seriously and implement
      appropriate technical and organizational measures to protect against
      unauthorized access, alteration, disclosure, or destruction of your data.
      However, no security system is completely impenetrable, and we cannot
      guarantee the security of our systems or your data.
    </p>

    <h2>8. Your Rights</h2>
    <p>
      Depending on your location, you may have the following rights regarding
      your personal data:
    </p>
    <ul>
      <li>
        Access: You have the right to request a copy of the Personal Information
        we hold about you.
      </li>
      <li>
        Correction: You may request corrections to any inaccuracies in your
        Personal Information.
      </li>
      <li>
        Deletion: You can request the deletion of your Personal Information
        under certain circumstances.
      </li>
      <li>
        Objection: You may object to the processing of your data or request
        restrictions on its use.
      </li>
      <li>
        Data Portability: You have the right to request the transfer of your
        Personal Information to another service provider.
      </li>
      <li>
        Right to be Forgotten: You have the right to request that your data with
        us be forgotten.
      </li>
      <li>
        Right in Relation to Automated Decision Making: You are entitled to
        human intervention in automated decision-making processes.
      </li>
      <li>
        Right to Withdraw Consent: You have the right to withdraw consent to the
        processing of your personal data at any time.
      </li>
    </ul>
    <p>
      To exercise any of these rights, please contact us using the contact
      information provided below.
    </p>

    <h2>9. Cookies and Tracking Technologies</h2>
    <p>
      We use cookies and similar tracking technologies to enhance your
      experience, analyze usage, and deliver personalized content. You can
      manage your cookie preferences through your browser settings.
    </p>

    <h2>10. International Data Transfers</h2>
    <p>
      Your information may be transferred to and processed in countries other
      than your own. We take steps to ensure that your data is treated securely
      and in accordance with this Privacy Policy, regardless of where it is
      processed.
    </p>

    <h2>11. Third-Party Links</h2>
    <p>
      Our app may contain links to third-party websites or services. We are not
      responsible for the privacy practices or the content of these third
      parties. We encourage you to review their privacy policies.
    </p>

    <h2>12. Changes to This Privacy Policy</h2>
    <p>
      We may update this Privacy Policy from time to time to reflect changes in
      our practices, technologies, legal requirements, or other factors. We will
      notify you of any significant changes by posting the new Privacy Policy on
      our app with a revised "Last Updated" date.
    </p>

    <h2>13. Contact Us</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy or our
      data practices, please contact us at:
    </p>
    <p>
      Peakpoint Innovations Limited
      <br />
      Email: <a href="mailto:hello@livio.life">hello@livio.life</a>
    </p>

    <p>© 2024 Peakpoint Innovations Limited. All rights reserved.</p>
  </div>
);

export default PrivacyPolicyComponent;
