import 'bootstrap/dist/css/bootstrap.css';
import DashboardTop from './dasboardTop';
import DashboardTable from './dashboardTable';

function DashboardPageIndex() {
  return (
    <>
        <div style={{backgroundColor:"#E6E8F0"}}>
            <DashboardTop />
            <DashboardTable />
        </div>
        
    </>
    
  );
}

export default DashboardPageIndex;


