import React from "react";
import styles from "./Footer.module.css";
import Livio from "../../assets/credpallogo2.png";
import {
  FooterLinkedin,
  FooterTiktok,
  FooterWhatsapp,
  FooterYoutube,
} from "../../assets/icon";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={styles.logoSection}>
          <img src={Livio} alt="Livio Logo" className={styles.logo} />
          <div className={styles.socialIcons}>
            <a href="#">
              {/* <img src={FooterYoutube} alt="YouTube" /> */}
              <FooterYoutube />
            </a>
            <a href="#">
              <FooterWhatsapp />
            </a>
            <a href="#">
              <FooterLinkedin />
            </a>
            <a href="#">
              <FooterTiktok />
            </a>
          </div>
          <div className={styles.buttons}>
            <div>
              <p>Available on</p>
              <a
                href="https://play.google.com/store/apps/details?id=com.exolvemedia.naijarecipes&pcampaignid=web_share"
                target="_blank"
                rel="noreferrer"
              >
                <button>Play Store</button>
              </a>
            </div>

            <div>
              <p>Join Waitlist</p>
              <Link to="waitlist">
                <button>App Store</button>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.footerColumn}>
          <h4>Company</h4>
          <ul>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#">Our Mission</a>
            </li>
            <li>
              <a href="#">Communities</a>
            </li>
          </ul>
        </div>

        <div className={styles.footerColumn}>
          <h4>Features</h4>
          <ul>
            <li>
              <a href="#">Recipes</a>
            </li>
            <li>
              <a href="#">Meal Planner</a>
            </li>
            <li>
              <a href="#">Communities</a>
            </li>
          </ul>
        </div>

        <div className={styles.footerColumn}>
          <h4>Resources</h4>
          <ul>
            <li>
              <a href="#">Blog</a>
            </li>
            <li>
              <a href="#">Nutrition Calculator</a>
            </li>
            <li>
              <a href="#">Dietary Facts</a>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.footerBottom}>
        <p>
          &copy; {new Date().getFullYear()} Recipes from Nigeria. All rights
          reserved.
        </p>
        <div className={styles.footerLinks}>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms</a>
          <a href="#">Security</a>
          <a href="#">Do not sell my data</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
