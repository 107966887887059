// import React, { useState } from "react";
import styles from "./ProfileInfo.module.css";
// import Temitope from "../../assets/starChef/temitope.png";
import Temitope from "../../assets/starChef/tope-main.png";
import ChefBadge from "../../assets/starChef/chefBadge.png";
import {
  ClockIcon,
  IngredientsIcon,
  Linkedin,
  Whatsapp,
  Youtube,
} from "../../assets/icon";

const ProfileInfo = () => {
  // const [following, setFollowing] = useState(false);
  return (
    <div className={styles.profileDetails}>
      <div className={styles["profile-top_wrapper"]}>
        <div className={styles["left"]}>
          <div className={styles["profile-image_wrapper"]}>
            <img
              src={Temitope}
              alt="Profile"
              className={styles.profilePicture}
            />
            <img
              src={ChefBadge}
              alt="chef-badge"
              className={styles["chef-badge"]}
            />
          </div>
          <div className={styles.stats}>
            <h1 className={styles.name}>Temitope Alao-Sanni</h1>
            <p className={styles.title}>Registered Dietician-Nutritionist</p>
            <div className={styles["stats-flex"]}>
              <div>
                <ClockIcon /> 1200 minutes
              </div>
              <div>
                <IngredientsIcon />
                15 ingredients
              </div>
              <div>
                <span className={styles["4.0"]}>4.0</span> Average rating
              </div>
            </div>
          </div>
        </div>
        {/* <button onClick={() => setFollowing((prev) => !prev)}>
          {following ? "Following" : "Follow"}
        </button> */}
      </div>
      <div className={styles.bio}>
        <p>About me</p>
        <p>
          As a certified health education specialist and a registered dietitian
          with the Dietitians Association of Nigeria, Temitope is committed to
          providing evidence-based nutrition advice that is both accessible and
          effective.
        </p>
      </div>
      <div className={styles.info}>
        <div>
          <strong>License </strong>
          <p>Registered Dietitian, Dietitians Association of Nigeria</p>
          <span className={styles["reg"]}>Reg ID: 0312AND019011AS</span>
          <p>Certified Health Education Specialist (CHES)</p>
          <span className={styles["reg"]}>Reg ID: 0312AND019011AS</span>
        </div>
        <div>
          <strong>Education </strong>
          <p>BSc in Nutrition and Dietetics (FUNAAB)</p>
          <p>Master of Public Health (Liberty University)</p>
        </div>
        <div>
          <strong>Email </strong>
          <p>temitopealaosam@gmail.com</p>
          <div className={styles["info-flex"]}>
            <a href="#">
              <Youtube />
            </a>
            <a href="#">
              <Whatsapp />
            </a>
            <a
              href="https://www.linkedin.com/in/temitopealaosanni"
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin />
            </a>
          </div>
        </div>
        <div>
          <strong>Location </strong>
          <p>Lagos, Nigeria</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
