import "bootstrap/dist/css/bootstrap.css";
import hero from "../../assets/hero.png";
import mobileHeroPage from "../../assets/mobileHeroPage.png";
import add from "../../assets/add.png";
import addMobile from "../../assets/addMobile.png";

import { useNavigate } from "react-router-dom";
import "../../styles/index.css";

function HeroPage({ toggleShowGetStarted }) {
  let navigate = useNavigate();

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 70 }}>
        {/* <section className="bsb-hero-1 px-3 bsb-overlay bsb-hover-pull" style={{height:600, backgroundImage: `url(${hero})`, backgroundRepeat:'no-repeat', backgroundAttachment:"fixed", backgroundSize:'100% 100%' }}> */}
        <section
          className="myhero-bg bsb-hero-1 px-3 bsb-overlay bsb-hover-pull img-fluid "
          style={{
            backgroundImage: `url(${hero})`,
            height: "auto",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className=" col-xxl-8  py-1 mt-5">
              <div className="row flex-lg-row  g-6 py-5">
                <div style={{ marginTop: -80 }} className="col-lg-8">
                  <p
                    style={{
                      fontWeight: "initial",
                      lineHeight: 1.2,
                      marginTop: 80,
                    }}
                    className="display-4 lh-6 mb-3 text-white "
                  >
                    <img
                      className="position-absolute"
                      src={add}
                      style={{ marginLeft: -28, marginTop: -35 }}
                      alt="+"
                    />
                    Healthy Foods by
                    <br />
                    African Chefs.
                    <br />
                    Made Easy.
                  </p>

                  <p
                    style={{ fontWeight: 400, lineHeight: 1.2, fontSize: 32 }}
                    className="col-lg-9 lead text-white fs-3"
                  >
                    “Your food can either be the safest and most powerful form
                    of medicine, or the slowest form of poison.” - Ann Wigmore.
                  </p>
                  <div
                    style={{ marginTop: 30 }}
                    className="d-grid gap-1 d-md-flex justify-content-md-start"
                  >
                    <button
                      // onClick={() => {
                      //   navigate("/waitlist");
                      // }}
                      onClick={() => {
                        toggleShowGetStarted && toggleShowGetStarted();
                      }}
                      type="button"
                      style={{
                        width: 220,
                        height: 55,
                        padding: "25",
                        borderColor: "#79FAA5",
                        borderWidth: 3,
                        color: "#79FAA5",
                      }}
                      className="fw-bold btn fs-6 btn-lg btn-outline-secondary rounded-pill px-20 "
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <-- Show on Mobile, Hide on Laptop ---> */}
        <section
          className="myhero-bg-mb bsb-hero-1 px-3 bsb-overlay bsb-hover-pull img-fluid "
          style={{
            backgroundImage: `url(${mobileHeroPage})`,
            height: "auto",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="container col-xxl-8  py-5 mt-5">
              <div className="row flex-lg-row  g-5 py-5">
                <div style={{ marginTop: -30 }} className="col-lg-6">
                  <p
                    style={{
                      fontWeight: "initial",
                      lineHeight: 1.4,
                      textAlign: "center",
                    }}
                    className="display-5 lh-6 mb-3 text-white "
                  >
                    <img
                      className="position-absolute"
                      src={addMobile}
                      style={{
                        marginLeft: -18,
                        marginBottom: 100,
                        marginTop: -10,
                      }}
                      alt="+"
                    />
                    Healthy Foods by
                    <br />
                    African Chefs.
                    <br />
                    Made Easy.
                  </p>

                  <p
                    style={{
                      fontWeight: "normal",
                      lineHeight: 1.2,
                      textAlign: "center",
                    }}
                    className="lead text-white fs-5"
                  >
                    “Your food can either be the safest and most powerful form
                    of medicine, or the slowest form of poison.” - Ann Wigmore.
                  </p>
                  <div
                    style={{ marginTop: 30 }}
                    className="d-grid gap-1 d-md-flex justify-content-md-start"
                  >
                    <button
                      onClick={() => {
                        toggleShowGetStarted && toggleShowGetStarted();
                      }}
                      type="button"
                      style={{
                        backgroundColor: "##79FAA5",
                        marginLeft: "10%",
                        width: 220,
                        height: 55,
                        padding: "25",
                        borderColor: "#79FAA5",
                        borderWidth: 3,
                        color: "#79FAA5",
                      }}
                      className="fw-bold btn fs-6 btn-lg rounded-pill px-20 "
                    >
                      {/* Join Our Waitlist */}
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <-- End Of Show on Mobile, Hide on Laptop ---> */}
        </section>
      </div>
    </>
  );
}

export default HeroPage;
