import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import dashboardLogo from "../../assets/dashboardLogo.png";

function DashboardNavBar2() {
  return (
    <>
      {/* <!-- Header --> */}
      <header id="header-demo">
        <nav className="navbar navbar-expand-sm bg-body-tertiary">
          <div className="container">
            <a className="navbar-brand d-sm-none" href="#!">
              <img
                src={dashboardLogo}
                className="img-fluid"
                alt="BootstrapBrain Logo"
                width="135"
                height="44"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#bsbNavbar"
              aria-controls="bsbNavbar"
              aria-label="Toggle Navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="bsbNavbar"
              aria-labelledby="bsbNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="bsbNavbarLabel">
                  Menu
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav">
                  <li className="navbar-toggler-icon nav-item me-3">
                    <a
                      className="nav-link"
                      href="#!"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#bsbSidebar1"
                      aria-controls="bsbSidebar1"
                    >
                      <i className="bi-filter-left fs-3 lh-1"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#!"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="#!">
                      Blog
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="#!">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* <!-- Main --> */}
      <div id="main-demo">
        <section className="my-3 my-md-4">
          <div className="container">
            <div className="row gy-3">
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-airplane mb-3"></i>
                  <span className="fs-7 text-body-secondary">Airplane</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-alarm mb-3"></i>
                  <span className="fs-7 text-body-secondary">Alarm</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-archive mb-3"></i>
                  <span className="fs-7 text-body-secondary">Archive</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-aspect-ratio mb-3"></i>
                  <span className="fs-7 text-body-secondary">Ratio</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-award mb-3"></i>
                  <span className="fs-7 text-body-secondary">Award</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-bag mb-3"></i>
                  <span className="fs-7 text-body-secondary">Bag</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-balloon mb-3"></i>
                  <span className="fs-7 text-body-secondary">Baloon</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-balloon-heart mb-3"></i>
                  <span className="fs-7 text-body-secondary">Baloon</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-ban mb-3"></i>
                  <span className="fs-7 text-body-secondary">Ban</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-bank mb-3"></i>
                  <span className="fs-7 text-body-secondary">Bank</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-bar-chart mb-3"></i>
                  <span className="fs-7 text-body-secondary">Chart</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-basket mb-3"></i>
                  <span className="fs-7 text-body-secondary">Basket</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-battery mb-3"></i>
                  <span className="fs-7 text-body-secondary">Battery</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-bell mb-3"></i>
                  <span className="fs-7 text-body-secondary">Bell</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-book mb-3"></i>
                  <span className="fs-7 text-body-secondary">Book</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-briefcase mb-3"></i>
                  <span className="fs-7 text-body-secondary">Briefcase</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-brightness-high mb-3"></i>
                  <span className="fs-7 text-body-secondary">Brightness</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-brush mb-3"></i>
                  <span className="fs-7 text-body-secondary">Brush</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-bug mb-3"></i>
                  <span className="fs-7 text-body-secondary">Bug</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-calendar mb-3"></i>
                  <span className="fs-7 text-body-secondary">Calendar</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-camera mb-3"></i>
                  <span className="fs-7 text-body-secondary">Camera</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-camera-video mb-3"></i>
                  <span className="fs-7 text-body-secondary">Video</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-cart-plus mb-3"></i>
                  <span className="fs-7 text-body-secondary">Cart</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-cast mb-3"></i>
                  <span className="fs-7 text-body-secondary">Cast</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-chat mb-3"></i>
                  <span className="fs-7 text-body-secondary">Chat</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-check-lg mb-3"></i>
                  <span className="fs-7 text-body-secondary">Check</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-chevron-double-up mb-3"></i>
                  <span className="fs-7 text-body-secondary">Chevron</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-slash-circle mb-3"></i>
                  <span className="fs-7 text-body-secondary">Slash</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-clipboard mb-3"></i>
                  <span className="fs-7 text-body-secondary">Clipboard</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-clock mb-3"></i>
                  <span className="fs-7 text-body-secondary">Clock</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-cloud mb-3"></i>
                  <span className="fs-7 text-body-secondary">Cloud</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-cloud-fog2 mb-3"></i>
                  <span className="fs-7 text-body-secondary">Cloud</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-code mb-3"></i>
                  <span className="fs-7 text-body-secondary">Code</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-collection mb-3"></i>
                  <span className="fs-7 text-body-secondary">Collection</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-copy mb-3"></i>
                  <span className="fs-7 text-body-secondary">Copy</span>
                </h2>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <h2 className="d-flex flex-column align-items-center justify-content-center bg-body-secondary rounded display-6 p-5">
                  <i className="bi-cursor mb-3"></i>
                  <span className="fs-7 text-body-secondary">Cursor</span>
                </h2>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Aside --> */}
      <aside
        className="bsb-sidebar-1 offcanvas offcanvas-start"
        tabIndex="-1"
        id="bsbSidebar1"
        aria-labelledby="bsbSidebarLabel1"
      >
        <div className="offcanvas-header">
          <a className="sidebar-brand" href="#!">
            <img
              src={dashboardLogo}
              id="bsbSidebarLabel1"
              className="img-fluid"
              alt="BootstrapBrain Logo"
              width="135"
              height="44"
            />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body pt-0">
          <hr className="sidebar-divider mb-3" />
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link p-3 active bg-light rounded"
                data-bs-toggle="collapse"
                href="#dashboardExamples"
                role="button"
                aria-expanded="true"
                aria-controls="dashboardExamples"
              >
                <div className="nav-link-icon text-primary">
                  <i className="bi bi-house-gear"></i>
                </div>
                <span className="nav-link-text fw-bold">Dashboards</span>
              </a>
              <div className="collapse show" id="dashboardExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-primary-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Default</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-primary-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Sales</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-primary-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Marketing</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-primary-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Directory</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-primary-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Analytic</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item mt-3">
              <h6 className="py-1 text-secondary text-uppercase fs-7">Pages</h6>
            </li>
            <li className="nav-item">
              <a
                className="nav-link p-3"
                data-bs-toggle="collapse"
                href="#pageExamples"
                role="button"
                aria-expanded="false"
                aria-controls="pageExamples"
              >
                <div className="nav-link-icon text-danger">
                  <i className="bi bi-folder"></i>
                </div>
                <span className="nav-link-text fw-bold">Pages</span>
              </a>
              <div className="collapse" id="pageExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-danger-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Account</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-danger-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Profile</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-danger-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-danger-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Projects</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-danger-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Invoice</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-danger-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Pricing</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link p-3"
                data-bs-toggle="collapse"
                href="#authExamples"
                role="button"
                aria-expanded="false"
                aria-controls="authExamples"
              >
                <div className="nav-link-icon text-success">
                  <i className="bi bi-gear"></i>
                </div>
                <span className="nav-link-text fw-bold">Authentication</span>
              </a>
              <div className="collapse" id="authExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-success-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Sign In</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-success-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Sign Up</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-success-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Reset Password</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-success-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Lock</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-success-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Error</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-success-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">2FA</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link p-3"
                data-bs-toggle="collapse"
                href="#ecommerceExamples"
                role="button"
                aria-expanded="false"
                aria-controls="ecommerceExamples"
              >
                <div className="nav-link-icon text-info">
                  <i className="bi bi-cart"></i>
                </div>
                <span className="nav-link-text fw-bold">Ecommerce</span>
              </a>
              <div className="collapse" id="ecommerceExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-info-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Overview</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-info-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Products</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-info-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Orders</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-info-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Referral</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link p-3"
                data-bs-toggle="collapse"
                href="#componentExamples"
                role="button"
                aria-expanded="false"
                aria-controls="componentExamples"
              >
                <div className="nav-link-icon text-warning">
                  <i className="bi bi-database-check"></i>
                </div>
                <span className="nav-link-text fw-bold">Components</span>
              </a>
              <div className="collapse" id="componentExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-warning-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Buttons</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-warning-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Charts</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-warning-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Forms</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-warning-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Icons</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-warning-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Widgets</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-warning-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Notifications</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item mt-3">
              <h6 className="py-1 text-secondary text-uppercase fs-7">Docs</h6>
            </li>
            <li className="nav-item">
              <a
                className="nav-link p-3"
                data-bs-toggle="collapse"
                href="#basicExamples"
                role="button"
                aria-expanded="false"
                aria-controls="basicExamples"
              >
                <div className="nav-link-icon text-dark">
                  <i className="bi bi-pen"></i>
                </div>
                <span className="nav-link-text fw-bold">Basic</span>
              </a>
              <div className="collapse" id="basicExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-dark-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Getting Started</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-dark-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Foundation</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-dark-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">FAQs</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link p-3"
                data-bs-toggle="collapse"
                href="#frameworkExamples"
                role="button"
                aria-expanded="false"
                aria-controls="frameworkExamples"
              >
                <div className="nav-link-icon text-dark">
                  <i className="bi bi-shield-plus"></i>
                </div>
                <span className="nav-link-text fw-bold">Framework</span>
              </a>
              <div className="collapse" id="frameworkExamples">
                <ul className="nav flex-column ms-4">
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-dark-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Developers</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-dark-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">API</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link link-secondary"
                      aria-current="page"
                      href="#!"
                    >
                      <div className="nav-link-icon text-dark-emphasis">
                        <i className="bi bi-arrow-right-short"></i>
                      </div>
                      <span className="nav-link-text">Changelog</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <hr className="sidebar-divider my-4" />
          <div className="bg-light rounded-3 position-relative px-4 pt-5 pb-4 mt-7">
            <div className="bsb-w-80 bsb-h-80 d-flex align-items-center justify-content-center text-bg-primary border border-5 border-white rounded-circle position-absolute top-0 start-50 translate-middle">
              <i className="bi bi-rocket-takeoff lh-1 fs-3"></i>
            </div>
            <div className="text-center">
              <h3 className="h5">Pro Snippets</h3>
              <p className="fs-7">
                Our meticulously crafted code snippets are handy for everyday
                tasks, allowing you to breeze through your projects.
              </p>
              <div className="d-grid m-0">
                <a
                  className="btn btn-primary rounded-pill"
                  href="https://bootstrapbrain.com/license/"
                  role="button"
                >
                  Upgrade
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>

      {/* <!-- Footer --> */}
      <footer className="footer bg-body-tertiary">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="py-3">
                Built by{" "}
                <a
                  href="https://bootstrapbrain.com/"
                  className="link-secondary text-decoration-none"
                >
                  BootstrapBrain
                </a>{" "}
                with <span className="text-primary">&#9829;</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default DashboardNavBar2;
