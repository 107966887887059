import MealPlanner from "../../components/mealPlanner/MealPlanner";
import OtherAllStars from "../../components/otherAllStars/OtherAllStars";
import ProfileInfo from "../../components/profileInfo/ProfileInfo";
import StarChefHead from "../../components/starChefHead/StarChefHead";
import FooterPage from "../home/Footer";
import NavBar from "../home/NavBar";
import Footer from "../../components/footer/Footer";
import styles from "./StarChef.module.css";

export default function StarChef() {
  return (
    <>
      <NavBar />
      <section className={styles["chef-section"]}>
        <StarChefHead />
        <ProfileInfo />
        <MealPlanner />
        <OtherAllStars />
      </section>
      <Footer />
      {/* <FooterPage /> */}
    </>
  );
}
