import React from "react";
import styles from "./OtherAllStars.module.css";
import ChefK from "../../assets/starChef/ChefK.png";
import ChefTang from "../../assets/starChef/ChefTang.png";
import ChefAima from "../../assets/starChef/ChefAima.png";
import ChefTomisin from "../../assets/starChef/ChefTomisin.png";
import ChefSnow from "../../assets/starChef/ChefSnow.png";
import ChefOmolara from "../../assets/starChef/ChefOmolara.png";

const allStars = [
  { id: 1, name: "Chef K", img: ChefK },
  { id: 2, name: "Chef Tang", img: ChefTang },
  { id: 3, name: "Chef Aima", img: ChefAima },
  { id: 5, name: "Chef Tomisin", img: ChefTomisin },
  { id: 6, name: "Chef Snow", img: ChefSnow },
  { id: 7, name: "Chef Omolara", img: ChefOmolara },
];

const OtherAllStars = () => {
  return (
    <div className={styles.otherAllStarsContainer}>
      <h2 className={styles.sectionTitle}>Other All-stars</h2>
      <div className={styles.allStarsGrid}>
        {allStars.map((star) => (
          <div key={star.id} className={styles.starItem}>
            <img src={star.img} alt={star.name} className={styles.starImage} />
            <p>{star.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherAllStars;
