import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
//https://bootstrapbrain.com/component/bootstrap-table-card-snippet/#preview
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import success from "../../assets/success.png";
import warning from "../../assets/warning.png";
// import topRecipe from "../../assets/topRecipe.png";

import { shoppingListTop } from "./data";

function ShoppingListTableTop() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  return (
    <>
      <section style={{ marginTop: "-5%" }} className="py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 col-xl-12">
              <div className="card widget-card border-light shadow-sm">
                <div className="card-body p-4">
                  <div style={{ lineHeight: 0.5, marginTop: "2%" }}>
                    <p
                      style={{
                        color: "#696F8C",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                      className="card-title widget-card-title mb-4"
                    >
                      Active ingredients
                    </p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {/* <img style={{marginTop:-1.2, marginRight:7}} className="h-50" src={topRecipe} alt="✓" /> */}
                      <p
                        style={{
                          color: "#696F8C",
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        className="mt-0 mb-4"
                      >
                        These are the list of ingredients on saved on this
                        user’s profile{" "}
                      </p>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-border table-hover bsb-table-xl text-nowrap align-middle m-0">
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        {shoppingListTop.map((u) => (
                          <tr key={u.key}>
                            <td
                              style={{
                                padding: 20,
                                fontSize: 14,
                                marginRight: "-5%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#2D3748",
                                  display: "flex",
                                }}
                                className="small text-gray"
                              >
                                <img
                                  style={{
                                    height: "5%",
                                    width: "5%",
                                    marginRight: "2%",
                                  }}
                                  src="https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg"
                                  className="avatar  avatar-sm rounded-circle "
                                  alt="user1"
                                />
                                <span> {u.food} </span>
                              </div>
                            </td>
                            <td
                              style={{
                                padding: 20,
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#696F8C",
                                backgroundColor: "white",
                                width: 10,
                              }}
                            >
                              {/* <div >Add flag </div> */}
                              <button
                                onClick={() => {
                                  setOpen(true);
                                }}
                                type="button"
                                style={{
                                  border: "none",
                                  alignSelf: "center",
                                  backgroundColor: "white",
                                  borderColor: "white",
                                }}
                              >
                                Add flag{" "}
                              </button>
                            </td>
                            <td
                              className="fw-bold"
                              style={{
                                padding: 20,
                                fontSize: 16,
                                fontWeight: 500,
                                color: "#30B15C",
                                backgroundColor: "white",
                                width: 10,
                              }}
                            >
                              Edit
                            </td>
                            {/* <td className="fw-normal" style={{padding:20, fontSize:14, fontWeight:700, color:"#3366FF"}}>
                                        <span className="fw-bold">{u.saveRate}</span>

                                        <div style={{height:3.5, }} className="progress">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: '60%', height:3.5, backgroundColor:"#3366FF" }}
                                                aria-valuenow="60"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>

                       
                                    </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              backgroundColor: "white",
              top: "50%",
              width: 400,
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              borderRadius: "5%",
            }}
          >
            <div
              className="modal-dialog modal-dialog-centered  rounded "
              role="document"
            >
              <div style={{ borderRadius: 20 }} className="modal-content  ">
                <div className="modal-body text-center p-lg-2 ">
                  <img
                    style={{ marginTop: 50 }}
                    loading="lazy"
                    src={warning}
                    alt="!"
                  />

                  <h4
                    style={{ fontWeight: 600, fontSize: 24 }}
                    className=" mt-4"
                  >
                    Are you sure?
                  </h4>
                  {/* <p style={{color:'#30B15C', marginTop:-5 }} className=" fs-4 fw-bold">Livio family!</p> */}

                  <p
                    style={{ color: "#696F8C", fontSize: 18, fontWeight: 500 }}
                    className="mt-4 "
                  >
                    Are you sure you want to flag this
                    <br /> ingredient as unhealthy?
                  </p>

                  {/* <hr style={{width:"100%", height:2, marginTop:60, marginLeft:0, marginRight:0 }} />
                   */}
                  <div className="col-12 mt-4 mb-4">
                    <div
                      style={{
                        justifyContent: "space-around",
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "4%",
                      }}
                    >
                      <button
                        style={{
                          height: 50,
                          width: "40%",
                          borderRadius: 10,
                          color: "#474D66",
                          borderColor: "#8F95B2",
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                        className="btn  "
                        type="submit"
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          backgroundColor: "#D14343",
                          height: 50,
                          width: "40%",
                          borderRadius: 10,
                          color: "white",
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                        className="btn  "
                        type="submit"
                      >
                        Add flag
                      </button>
                    </div>
                  </div>
                  {/* <button  onClick={() =>{ alert("welcome")}} type="button" style={{border:'none', alignSelf:"center", backgroundColor:"white", borderColor:'white', textDecoration:"underline", fontWeight:"bold", marginTop:40, marginBottom:40 }} className=" fs-5 text-dark link-secondary"  >Go Back Home</button> */}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </section>
    </>
  );
}

export default ShoppingListTableTop;
