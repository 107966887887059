import "./privacyPolicy.css";
const DeleteAccountComponent = () => (
  <div className="policy-container" style={{ textAlign: "center" }}>
    <h1>Delete Account</h1>

    <p>
      <strong>
        a) Sign in to Your Livio Account: Open the Livio mobile app and sign in
        using your email and password.
      </strong>
    </p>
    <p>
      <strong>
        b) Access the App Menu: After signing in, click on the menu icon at the
        bottom right of the page.
      </strong>
    </p>
    <p>
      <strong>
        c) Navigate to Settings: Find the "Delete Account" feature within your
        settings. Click on it to proceed.
      </strong>
    </p>
    <p>
      <strong>
        d) Confirm Your Decision: The app will prompt you to input your password
        to confirm your decision to delete your account. Click on "Continue" to
        delete your account.
      </strong>
    </p>
  </div>
);

export default DeleteAccountComponent;
