import "bootstrap/dist/css/bootstrap.css";
//https://www.educba.com/bootstrap-vertical-menu/
//https://stackoverflow.com/questions/48248975/bootstrap-navbar-collapse-dont-work-react-create-react-app
// https://getbootstrap.com/docs/4.0/components/navbar/

import dashboardLogo from "../../assets/dashboardLogo.png";
import dashboard from "../../assets/dashboard.png";
import arrowRight from "../../assets/arrowRight.png";
import "../../styles/hover.css";

// import DashboardPageIndex from '.';
import DashboardRecipeTable from "./dashboardRecipeTable";
import DashboardRecipeGraphIndex from "./dashboardRecipeGraphIndex";

function DashboardNavBar() {
  return (
    <>
      <div style={{ backgroundColor: "#FAFBFF" }} className="row">
        <div
          className="col-3"
          style={{ width: "19%", backgroundColor: "#FFFFFF" }}
        >
          <nav class="navbar">
            <button
              style={{ marginTop: "20%", border: "none", marginLeft: "5%" }}
              className="navbar-brand navbar-toggler"
              data-toggle="collapse"
              data-target="#colp"
              href="#"
            >
              <img
                src={dashboardLogo}
                className="d-inline-block align-top"
                alt="credpalLogo"
              />
            </button>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#colp">
                     <span className="navbar-toggler-icon" style={{backgroundColor:"darkblue"}}></span>
                    </button> */}
            <div
              style={{ marginTop: "20%" }}
              className="collapse navbar-collapse"
              id="colp"
            >
              <ul class="nav navbar-nav">
                <li class="nav-item">
                  <a
                    className="nav-link mybtn"
                    href="#"
                    style={{
                      marginLeft: 2,
                      padding: "6%",
                      fontSize: 14,
                      color: "#696F8C",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="mybtn">
                      <img
                        src={dashboard}
                        className="d-inline-block"
                        alt="credpalLogo"
                        style={{ marginRight: 8, marginTop: -3 }}
                      />
                      Dashboard
                    </div>
                    <img
                      src={arrowRight}
                      style={{ marginTop: "2%" }}
                      className="d-inline-block h-50"
                      alt=">"
                    />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mybtn"
                    href="#"
                    style={{
                      marginLeft: 2,
                      padding: "6%",
                      fontSize: 14,
                      color: "#696F8C",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="mybtn">
                      <img
                        src={dashboard}
                        className="d-inline-block"
                        alt="credpalLogo"
                        style={{ marginRight: 8, marginTop: -3 }}
                      />
                      Users
                    </div>
                    <img
                      src={arrowRight}
                      className="d-inline-block h-50"
                      alt=">"
                    />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mybtn"
                    href="#"
                    style={{
                      marginLeft: 2,
                      padding: "6%",
                      fontSize: 14,
                      color: "#696F8C",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="mybtn">
                      <img
                        src={dashboard}
                        className="d-inline-block"
                        alt="credpalLogo"
                        style={{ marginRight: 8, marginTop: -3 }}
                      />
                      All-stars
                    </div>
                    <img
                      src={arrowRight}
                      className="d-inline-block h-50"
                      alt=">"
                    />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mybtn"
                    href="#"
                    style={{
                      marginLeft: 2,
                      padding: "6%",
                      fontSize: 14,
                      color: "#696F8C",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="mybtn">
                      <img
                        src={dashboard}
                        className="d-inline-block"
                        alt="credpalLogo"
                        style={{ marginRight: 8, marginTop: -3 }}
                      />
                      Content Creators
                    </div>
                    <img
                      src={arrowRight}
                      className="d-inline-block h-50"
                      alt=">"
                    />
                  </a>
                </li>

                <li style={{ marginTop: "10%" }} className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown link
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div style={{ backgroundColor: "#FAFBFF" }} className="col-9">
          {/* <DashboardPageIndex /> */}
          <DashboardRecipeGraphIndex />
          <DashboardRecipeTable />
        </div>
      </div>
    </>
  );
}

export default DashboardNavBar;

// import 'bootstrap/dist/css/bootstrap.css';
// //https://www.educba.com/bootstrap-vertical-menu/
// //https://stackoverflow.com/questions/48248975/bootstrap-navbar-collapse-dont-work-react-create-react-app
// // https://getbootstrap.com/docs/4.0/components/navbar/

// import credpalLogo from "../../assets/credpalLogo.png";

// function DashboardNavBar() {
//   return (
//     <>
//         <div className="row">
//             <div className="col-3">
//                 <nav class ="navbar bg-light">
//                     <button className="navbar-brand navbar-toggler" data-toggle="collapse" data-target="#colp" href="#">
//                         <img src={credpalLogo}  className="d-inline-block align-top" alt="credpalLogo" />
//                     </button>
//                     {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#colp">
//                      <span className="navbar-toggler-icon" style={{backgroundColor:"darkblue"}}></span>
//                     </button> */}
//                     <div className="collapse navbar-collapse" id="colp">
//                         <ul class ="nav navbar-nav">
//                             <li class ="nav-item">
//                               <a class ="nav-link" href="#"> Dashboard </a>
//                             </li>
//                             <li class ="nav-item">
//                              <a class ="nav-link" href="#"> Users </a>
//                             </li>
//                             <li class ="nav-item">
//                              <a class ="nav-link" href="#"> All-stars </a>
//                             </li>
//                             <li class ="nav-item">
//                                 <a class ="nav-link" href="#"> Content Creators </a>
//                             </li>

//                             <li className="nav-item dropdown">
//                                 <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                 Dropdown link
//                                 </a>
//                                 <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
//                                 <a className="dropdown-item" href="#">Action</a>
//                                 <a className="dropdown-item" href="#">Another action</a>
//                                 <a className="dropdown-item" href="#">Something else here</a>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </nav>
//             </div>

//             <div className="col-9">
//                 <div className="container">
//                  The Web Content in detail.
//                 </div>
//                 <div class ="container">
//                     <p> The vertical menu can place the left or right side of the web pages. <br /> But the default vertical menu placed on the left side. </p>
//                 </div>
//             </div>
//         </div>

//     </>
//   );
// }

// export default DashboardNavBar;
