import React from "react";
import styles from "./GetStartedModal.module.css";
import { PlayStoreIcon, AppStoreIcon } from "../../assets/icon";
import { Link } from "react-router-dom";

const GetStartedModal = ({ onClose }) => {
  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContainer}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <div className={styles.modalContent}>
          <h1 className={styles.heading}>Download the Livio App Today!</h1>
          <p className={styles.description}>
            Why wait? Transform your meal planning with expert nutrition
            guidance and mouth-watering recipes. Start your journey to better
            health now!
          </p>
          <div className={styles.buttons}>
            <div>
              <p>Available on</p>
              <a
                href="https://play.google.com/store/apps/details?id=com.exolvemedia.naijarecipes&pcampaignid=web_share"
                rel="noreferrer"
                target="_blank"
                className={styles.playStore}
              >
                <button>
                  <PlayStoreIcon />
                  Play Store
                </button>
              </a>
            </div>
            <div>
              <p>Join Waitlist</p>
              <Link to="/waitlist" className={styles.appStore}>
                <button>
                  <AppStoreIcon />
                  App Store
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedModal;
